



@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

#root {
  height: 100vh;
}

body, html {
  // padding: 5vh 5vw;
  height: 100vh;
  scroll-behavior: smooth;
  margin: 0;
  // font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}


@media screen and (min-width: 50em) {
  html {
    font-size: 2vw;
  }
}

h2 {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #1f0322;
  // font-size: 1.4em;
}

h3, h4, a, p, ul, li {
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;

}




.ui.top.fixed.menu.nav-bgd {
  background: #1f0322;
	// background-size: 400% 400%;
  // animation: gradient 15s ease infinite;
  
  // @keyframes gradient {
  //   0% {
  //     background-position: 0% 50%;
  //   }
  //   50% {
  //     background-position: 100% 50%;
  //   }
  //   100% {
  //     background-position: 0% 50%;
  //   }
  // }
  display: flex;
  justify-content: flex-end;
  a {
    padding: 1vh 1vw;
    text-decoration: none;
    color: #f1e9db;
    
  }
}

.ui.menu {
  font-size: 1em;
}

.nav-text {
  font-size: 1em;
}
// #1f0322
// 587792

.hero-section {
  height: 100vh;  
  background: linear-gradient(-45deg, #ff6666, #818aa3, #a491d3);
	background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}


.hero-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  h1 {
    font-size: 5em;
    color: #1f0322;
    // font-family: 'Quicksand', sans-serif;
    // font-family: 'Julius Sans One', sans-serif;
    font-family: 'Ubuntu', sans-serif;
    
  }
  h2 {
    width: 60vw;
    font-size: 1.8em;
    line-height: 1.4em;
    color: #f1e9db;
  }
  .bio-highlight {
    color: #222a30;
    font-weight: bold;
  }
  
}

.hero-arrow-down {
  text-align: center;
  a {
   padding-top: 10vh;
    position: absolute;
   
  }
}



// homepage mouse


$colorBg:  #222a30;
$colorOutline: #f1e9db; 
$colorOutlineFade: #4e5559;

$widthMouse: 52px;
$heightMouse: 88px;
$borderMouse: 6px;

$posMouse: 8px;
$posText: 2px;

$sizeTrackball: 10px;
$posTrackball: 20px;
$shrinkTrackball: 0.4;

$animDuration: infinite;

.mouse-container {
  display: flex;
  justify-content: center;
  // margin-top: 17vh;
  p {
    // margin-top: 8vh;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5em;
    color: $colorOutline;
    animation: 
      colorText $animDuration ease-out 5s,
      nudgeText $animDuration ease-out 5s;
  }
}



@mixin bgGradient {
  background:
    $colorOutlineFade
    linear-gradient(
      transparent 0%,
      transparent 50%,
      $colorOutline 50%,
      $colorOutline 100%
    );
}

.mouse {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 15vh;
  @include bgGradient;
  // position: relative;
  width: $widthMouse;
  height: $heightMouse;
  border-radius: 100px;
  background-size: 100% 200%;
  animation: 
    colorSlide $animDuration linear 5s,
    nudgeMouse $animDuration ease-out 5s;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: auto;
  }
  &:before {
    width: $widthMouse - $borderMouse;
    height: $heightMouse - $borderMouse;
    background-color: $colorBg;
    border-radius: 100px;
  }
  &:after {
    background-color: $colorOutline;
    width: $sizeTrackball;
    height: $sizeTrackball;
    border-radius: 100%;
    animation: trackBallSlide $animDuration linear 5s;
  }
}

@keyframes colorSlide { 
  0% { background-position: 0% 100%; }
  20% { background-position: 0% 0%; }
  21% { background-color: $colorOutlineFade; }
  29.99% { 
    background-color: $colorOutline;
    background-position: 0% 0%;
  }
  30% { 
    background-color: $colorOutlineFade;
    background-position: 0% 100%;
  }
  50% { background-position: 0% 0%; }
  51% { background-color: $colorOutlineFade; }
  59% { 
    background-color: $colorOutline;
    background-position: 0% 0%;
  }
  60% { 
    background-color: $colorOutlineFade;
    background-position: 0% 100%;
  }
  80% { background-position: 0% 0%; }
  81% { background-color: $colorOutlineFade; }
  90%, 100% { background-color: $colorOutline; }
}

@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
  6% { 
    opacity: 1;
    transform: scale(0.9) translateY($posTrackball/4);
  }
  14% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY($posTrackball*2);
  }
  15%, 19% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY(-$posTrackball);
  }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
  36% { 
    opacity: 1;
    transform: scale(0.9) translateY($posTrackball/4);
  }
  44% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY($posTrackball*2);
  }
  45%, 49% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY(-$posTrackball);
  }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
  66% { 
    opacity: 1;
    transform: scale(0.9) translateY($posTrackball/4);
  }
  74% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY($posTrackball*2);
  }
  75%, 79% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY(-$posTrackball);
  }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }
}

@keyframes nudgeMouse {
  0% { transform: translateY(0); }
  20% { transform: translateY($posMouse); }
  30% { transform: translateY(0); }
  50% { transform: translateY($posMouse); }
  60% { transform: translateY(0); }
  80% { transform: translateY($posMouse); }
  90% { transform: translateY(0); }
}

@keyframes nudgeText {
  0% { transform: translateY(0); }
  20% { transform: translateY($posText); }
  30% { transform: translateY(0); }
  50% { transform: translateY($posText); }
  60% { transform: translateY(0); }
  80% { transform: translateY($posText); }
  90% { transform: translateY(0); }
}

@keyframes colorText { 
  21% { color: $colorOutlineFade; }
  30% { color: $colorOutline; }
  51% { color: $colorOutlineFade; }
  60% { color: $colorOutline; }
  81% { color: $colorOutlineFade; }
  90% { color: $colorOutline; }
}

// About 
.about-container {
  // height: 100vh;
 
  h2 {
    padding-top: 7vh;
    padding-bottom: 2.6vh;
  }
  p {
    font-size: 1.3em;
    line-height: 1.45em;
    padding-bottom: 1.4vh;
  }
}

.interests {
  text-align: center;
}
.header-title {
  // font-family: 'Poppins', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-size: 2em;
	
  
  
}

.funky-underline {
  background-image: linear-gradient(120deg, #ff6666, #818aa3 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
  &:hover {
    background-size: 100% 88%;
    color: #f1e9db;
  }
}

// Projects 
.projects-container{
  margin-top: 3vh;
  h2 {
    text-align: center;
  }
  // width: 50vw;
  // display: flex;
  // flex-wrap: wrap;
}
.project-container {
  // width: 45vw;
  margin: 1em;
  margin-bottom: 3em;
  p {
    font-size: 1.2em;
  }
  h3 {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
  }
}
.readme-link {
  text-align: center;
  transition: transform .2s;
  a {
    text-decoration: none;
    color: inherit;
    
  }
  a:hover {
    color: #818aa3;
    
  }
  :hover {
    transform: scale(1.5);
  }

}
.aws-btn {

  --slider-height-percentage: 60%;
  --slider-transition-duration: 329ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #26456f;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #2d5182;
  --control-bullet-active-color: #26456f;
  --loader-bar-color: #000000;
  --loader-bar-height: 6px;
  }

  .slide-container > .awssld > .awssld__wrapper > .awssld__container > .awssld__box > .awssld__content > img {
    object-fit: contain;
    
  }

  .slide-container > .awssld > .awssld__wrapper > .awssld__container > .awssld__box > .awssld__content {
    background-color: inherit;
  }
  

  .project-info {
    margin-top: 6vh;
    // text-align: center;
  }

.project-tech {
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
  
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
 
        list-style-type: none;
        padding: 0.45em;
    }
  }
}


// Tech
.main-tech-container {
  // height: 50vh;
  h2 {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
}

.skills-header {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  
}

.tech-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3em;
  
}

.tech-logos{
  width: 60em;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1em;
      list-style-type: none;
      i {
        color: #818aa3
      }
  }
  }
}

.tech-para {
  width: 30em;
  padding: 2em;
  align-self: baseline;
  font-family: 'Poppins', sans-serif;
  font-size: 1.3em;
}

// Experience
.experience-container {
  // height: 50vh;
  // margin-top: 5vh;
  h2 {
    text-align: center;
  }
  a{
    text-decoration: none;
    color: #ff6666;
    font-weight: bold;
    
    
  }
  a:hover {
    text-decoration: none;
    color: #818aa3;

  }
  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1.4em;
  }

  h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2em;
  }
  p {
    font-size: 1.3em;
  }
  li {
    font-size: 1.3em;
    line-height: 1.5em;
  }
}

.project-link {
  transition: transform .2s;
  :hover {
    transform: scale(1.5);
  }
  
}

//  Contact
.contact {
  height: 10vh;
  h2 {
    text-align: center;
    // margin-bottom: 2vh;
    padding-top: 3vh;
  }
  
}
.contact-links {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3vh;
  
  a {
    
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    transition: transform .2s;
    :hover{
      transform: scale(1.3);
    }
    p {
      color: inherit;
      font-size: 1.2em;
      padding-left: 0.4em;
    }
  } 
  a:hover {
    text-decoration: none;
    color: #818aa3;
  }
}


footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 3vh;
  // position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 2vh;
  a {
    text-decoration: none;
    color: inherit;
  
  }
  a:hover {
    color: #818aa3;
  }
  p {
    margin-top: 3vh;
    text-transform: uppercase;
  }

}

.footer-icon {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
}